"use client"
import TextFieldComponent from "@/Inputs/TextFieldComponent";
import localStorageCall from "@/services/Methods/localstorage.hook";
import { Fragment, useContext, useState } from "react";
import { getCustomerRefferal } from "@/services/Redux/Reducer/CustomerSlice";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { Close, Search } from "@mui/icons-material";
import ButtonComponent from "@/Inputs/ButtonComponent";
import { ContextData, MiniLoader, _setTimeOut, storeAssigned } from "@/services/Methods/normalMethods";
import { RegionDropdown } from "react-country-region-selector";
import { createSelector } from 'reselect';
import { isUserLogin } from '@/services/Methods/normalMethods';
import Link from "next/link";

const JoinPopup = (props) => {

    // const [openPopup, setOpenPopup] = useState(true);
    const { joinPopup, setJoinPopup } = props; 
    return ( isUserLogin && localStorageCall().getItem('customerTypeDescr')?.customerType === "Advocate" && 
        <>
        <Transition appear show={joinPopup} as={Fragment}>
            <Dialog as="div" className="relative z-[1400]" onClose={() => console.log('close')}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-[rgba(0,0,0,0.8)]" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center px-[15px]">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className=" relative z-[1400] mx-4 sm:mx-0 w-full max-w-[800px] transform p-7 bg-white  text-left align-middle  transition-all rounded-md">
                                <div className="text-right absolute top-[10px] right-[10px]">
                                    <Close
                                        className=" ml-auto cursor-pointer"
                                        onClick={() => setJoinPopup(false)}
                                    />
                                </div>
                                <div className="py-2 text-center">
                                    <h3 className="font-bold mb-1">You are already an Advocate.</h3>
                                    <p className="py-2 font-medium text-blue-600 dark:text-blue-500 hover:underline"><a href={`/${storeAssigned()}/products`}>Please Continue Shopping</a></p>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
        </>
    )
}

export default JoinPopup;