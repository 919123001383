"use client";
import { isUserLogin, storeAssigned } from '@/services/Methods/normalMethods';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
// import { useTheme } from '@emotion/react';
import { Divider, IconButton, styled, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Clear } from '@mui/icons-material';
import localStorageCall from '@/services/Methods/localstorage.hook';
import { MyAccoutnAPI } from '@/services/Redux/Reducer/LoginSlice';
import { useDispatch } from 'react-redux';
import Link from 'next/link';

const drawerWidth = 240;

const NewCustomHeader = (props) => {
  const { open, setOpen, handleDrawerOpen, handleDrawerClose, handleSignin, handleLogout, joinPopup, setJoinPopup } = props;

  const theme = useTheme()

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const dispatch = useDispatch();


  function handleBackOffice() {
    if (isUserLogin) {
      dispatch(MyAccoutnAPI())
    }
    else {
      navigate(`/${storeAssigned()}/${ROUTING_TEXT?.login_routingName}`)
    }
  };

  return (
    <div className='mobile-sidebar4'>
      <Drawer sx={{ width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', }, }} variant="persistent" anchor="right" open={open}>
        <div className='cstm-mobile-toggle'>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerClose} edge="start" sx={{ mr: 2, ...(!open && { display: 'none' }) }}>
            <Clear />
          </IconButton>
        </div>
        <Divider />
        <div className='sidebar-menu'>
          <nav>

            <ul className='right-nav'>
              {
                isUserLogin && (
                  <>
                    <li><a href={`/${storeAssigned()}/products`}>Shop</a></li>
                    <li className="">
                      <Link href="" onClick={() => handleBackOffice()} >
                        Backoffice
                      </Link>
                    </li>
                  </>
                )
              }
              <li> <a href={`/${storeAssigned()}/main-enrollment`}>Join us</a> </li>
            </ul>


            <ul>
              <li>
                {isUserLogin ? (
                  <div>
                    <button onClick={handleLogout}>Sign out</button>
                  </div>
                ) : (
                  <>
                    <div onClick={handleSignin}>
                      <button>Sign in</button>
                    </div>
                  </>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </Drawer>
    </div>
  )
}

export default NewCustomHeader;