"use client";
import React from "react";
import Image from "next/image";
import _ from "lodash";
import { Viagologo } from "@/assets/images";
import { storeAssigned } from "@/services/Methods/normalMethods";
import { useRouter } from "next/navigation";

export const Footer = () => {
  const router = useRouter();

  const handleHome = () => {
    router.push(`/${storeAssigned()}`)
  }

  const renderAddress = (country) => {
    console.log("country", country);
    const europeanCountries = [
      "eu", "mt", "cy", "be", "de", "pl", "ro", "si", "it", "es", "at", "se",
      "ie", "fi", "cz", "ru", "sk", "lt", "lv", "pt", "gr", "hu", "dk", "hr",
      "no", "is", "nl", "nc", "re", "bg", "fr", "gb", "rs", "lu", "ch", "ee"
    ];

    const asiaAfricaBrazilUSCanadaCountries = [
      "us", "ca", "af", "am", "az", "bh", "bd", "bt", "bn", "kh", "cn", "cy", "ge",
      "in", "id", "ir", "iq", "il", "jp", "jo", "kz", "kw", "kg", "la", "lb", "my",
      "mv", "mn", "mm", "np", "kp", "om", "pk", "ph", "qa", "sa", "sg", "kr", "lk",
      "sy", "tj", "th", "tl", "tr", "tm", "ae", "uz", "vn", "ye", "br", "dz", "ao",
      "bj", "bw", "bf", "bi", "cv", "cm", "cf", "td", "km", "cg", "cd", "ci", "dj",
      "eg", "gq", "er", "sz", "et", "ga", "gm", "gh", "gn", "gw", "ke", "ls", "lr",
      "ly", "mg", "mw", "ml", "mr", "mu", "ma", "mz", "na", "ne", "ng", "rw", "st",
      "sn", "sc", "sl", "so", "za", "ss", "sd", "tz", "tg", "tn", "ug", "zm", "zw"
    ];

    if (asiaAfricaBrazilUSCanadaCountries.includes(country)) {
      return (
        <>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">PH 602.606.9500</p>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">PH 877.680.9622</p>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">(Toll Free from the US and Canada)</p>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">Monday – Thursday: 9 AM – 4 PM AZ</p>
          <a href="mailto:support@viagodirect.com" className="cursor-pointer futura_font_md text-[#444] leading-[27px] font-normal">support@viagodirect.com</a>
        </>
      );
    }

    if (europeanCountries.includes(country)) {
      return (
        <>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">PH 602.606.9500</p>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">PH 877.680.9622</p>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">(Toll Free from the US and Canada)</p>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">Monday – Thursday: 9 AM – 4 PM AZ</p>
          <a href="mailto:support_eu@viagodirect.com" className="cursor-pointer futura_font_md text-[#444] leading-[27px] font-normal">support_eu@viagodirect.com</a>
        </>
      );
    }
    if (country === 'co') {
      return (
        <>

          <p className="futura_font_md text-[#444] leading-[27px] font-normal">Cra 19 # 108 – 56 Local 3</p>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">Bogotá, Colombia</p>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">Monday – Friday: 9:00 AM - 5:00 PM</p>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">PH: 6042044595 </p>


          <a href="mailto:soporteco@viagodirect.com" className="cursor-pointer futura_font_md text-[#444] leading-[27px] font-normal">soporteco@viagodirect.com</a>
        </>
      );
    } else if (country === 'mx') {
      return (
        <>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">Plaza Palmillas, Blvd. Fundadores 2480 Local 2</p>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">Colonia Juárez C.P. 22040</p>
          <p className="futura_font_md text-[#444] leading-[27px] font-normal">PH:  81 4173 5840</p>
          <a href="mailto:soporteco@viagodirect.com" className="cursor-pointer futura_font_md text-[#444] leading-[27px] font-normal">soporteco@viagodirect.com</a>
        </>
      )
    }
    return (
      <>
        <p className="futura_font_md text-[#444] leading-[27px] font-normal">15160 N. Hayden Rd., Suite 200</p>
        <p className="futura_font_md text-[#444] leading-[27px] font-normal">Scottsdale, AZ 85260</p>
      </>
    );
  };

  const policesPDF = (country) => {
    const europeanCountries = [
      "eu", "mt", "cy", "be", "de", "pl", "ro", "si", "it", "es", "at", "se",
      "ie", "fi", "cz", "ru", "sk", "lt", "lv", "pt", "gr", "hu", "dk", "hr",
      "no", "is", "nl", "nc", "re", "bg", "fr", "gb", "rs", "lu", "ch", "ee"
    ];

    const asiaAfricaBrazilUSCanadaCountries = [
      "ca"
    ];

    if (asiaAfricaBrazilUSCanadaCountries.includes(country)) {
      return (
       'Viágo-PoliciesProcedures-CA.pdf'
      );
    }

    if (europeanCountries.includes(country)) {
      return (
       'Viago-PoliciesProcedures-EU-2.pdf' 
      );
    }

    return (
      'Viago-PoliciesProcedures.pdf'
      );
 
  };

  const termsCondtionPDF = (country) => {
    const europeanCountries = [
      "eu", "mt", "cy", "be", "de", "pl", "ro", "si", "it", "es", "at", "se",
      "ie", "fi", "cz", "ru", "sk", "lt", "lv", "pt", "gr", "hu", "dk", "hr",
      "no", "is", "nl", "nc", "re", "bg", "fr", "gb", "rs", "lu", "ch", "ee"
    ];

    const asiaAfricaBrazilUSCanadaCountries = [
      "ca"
    ];

    if (asiaAfricaBrazilUSCanadaCountries.includes(country)) {
      return (
       'ClubViago-MembershipTerms_Conditions_CA.pdf'
      );
    }

    if (europeanCountries.includes(country)) {
      return (
       'ClubViago-MembershipTerms&Conditions-EU-2.pdf' 
      );
    }

    return (
      'Club-Viago-MembershipTerms&Conditions.pdf'
      );
 
  };


  return (
    <footer className="footer lg:py-[72px] py-[50px] lg:pb-0 pb-0" style={{ display: "flex", background: "black", color: "white ", fontWeight: "bold" }}>
      <div className="container 2xl:max-w-[1920px] mx-auto 2xl:p-[32px] p-[15px]">
        <div className="row flex flex-wrap lg:flex-row flex-col-reverse">
          <div className="col-footer xl:max-w-[702px] lg:max-w-[460px] md:max-w-[100%] max-w-[100%] w-full flex 2xl:justify-center justify-start">
            <div className="max-w-[max-content]">
              <h5 className="xl:text-[45px] md:text-[35px] text-[30px] tracking-[3px] xl:leading-[55px] leading-[40px] font-medium futura_font text-start">Ready to Amplify Your Life?</h5>
              <p className="text-[18] text-[#444] font-normal futura_font_md pt-2 text-start">
                Join Viago today and start living
                with purpose, joy, and freedom.
              </p>
              <a href={`/${storeAssigned()}/main-enrollment`} className="futura_font text-[#000] px-[26px] py-[6px] bg-[#89fd00] text-[18px] font-semibold mt-6 flex rounded-full mr-auto max-w-[max-content]" >Sign Up Now</a>
            </div>
          </div>

          <div className="col-footer 2xl:max-w-[290px] w-full max-w-[max-content] lg:my-0 my-[30px] flex justify-center">
            <div className="w-auto">
              <h6 className="futura_font font-medium text-[27px] tracking-[3px] pt-0 text-start">Address</h6>
              <div className="pt-[9px] text-start">
                {
                  renderAddress(storeAssigned())
                }
              </div>
            </div>
          </div>
          <div className="lg:ms-auto xl:max-w-[350px] lg:max-w-[250px] max-w-[170px] 1 w-full flex mx-auto justify-center items-start cursor-pointer" onClick={handleHome}>
            <Image src={Viagologo} alt="Viago-logo" />
          </div>
        </div>
        <div className="copy_sec md:pt-[70px] pt-[30px]">
          <ul className="flex justify-center md:gap-[30px] gap-[15px]">
            {[
              { text: "@2024 Viago", href: "#" },
              { text: "All rights reserved", href: "#" },
              { text: "Privacy Policy", href: "/pdf/VIÁGO-Privacy-Policy.pdf" },
              { text: "Policies", href: `/pdf/${policesPDF(storeAssigned())}` },
              { text: "Income Disclosure", href: "/pdf/VIÁGO-Income-Disclosure-Statement.pdf" },
              { text: "Compensation Plan", href: "/pdf/2024-VIÁGO-Compensation-Plan-Summary.pdf" },
              { text: "Terms & Conditions", href: `/pdf/${termsCondtionPDF(storeAssigned())}` },
              { text: "Terms of Use", href: "#" },
            ].map((link, idx) => (
              <li key={idx}>
                <a
                  href={link.href}
                  target={link.href.startsWith("/pdf") ? "_blank" : undefined}
                  className="text-[#a9a9a9] hover:text-[#fff] no-underline lg:text-[12px] text-[10px] font-normal relative"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer >
  );
};
export default Footer;